/* eslint-disable @uizard-io/opinionated-linting/module-root-relative-paths */
// eslint-disable-next-line @uizard-io/opinionated-linting/module-root-relative-paths
import React from 'react';

import Media from '@uizard-io/styled-media-query';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import Styled from 'styled-components/macro';

import Button from 'components/buttons/Button';
import Content from 'components/layout/Content/Content';
import Layout from 'components/layout/Layout';
import MediaQuery from 'components/layout/MediaQuery';
import Meta from 'components/meta';
import BlogHighlight from 'components/pages/blog/BlogHighlight';
import FAQSection from 'components/pages/faq/FAQSection';
import HomeAutodesigner from 'components/pages/home/HomeAutodesigner';
import HomeAutodesignerLeft from 'components/pages/home/HomeAutodesignerLeft';
import HomeFeatured from 'components/pages/home/HomeFeatured';
import Shoutout from 'components/pages/signup/Shoutout';
import HeroBigTextBannerWithVideo from 'components/pages/solutions/HeroBigTextBannerWithVideo';
import SectionWithLeftImage from 'components/pages/solutions/SectionWithLeftImage';
import Description from 'components/texts/Description';
import Headline from 'components/texts/Headline';
import Tagline from 'components/texts/Tagline';
import constants from 'constants/index';

import howToBecomeAUiDesigner from '../../../content/ui-design/how_to_become_a_ui_designer.png';
import tenUiDesignTips from '../../../content/ui-design/ten_ui_design_tips.png';
import whatAreUiElements from '../../../content/ui-design/what_are_ui_elements.png';
import HomeExamples from '../../components/pages/home/HomeExamples';

const BlockCardGreyContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BlockCardGreyWrapper = Styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: ${(props) => props.theme.colors.gray.lighter};
  border-radius: 12px;

  ${Media.greaterThan('700px')`
    flex-direction: row-reverse;
  `}

  ${Media.greaterThan('1170px')`
    max-width: 86%;
  `}
`;

const BlockCardGreyContent = Styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
  padding: 3rem;

  ${Media.greaterThan('700px')`
    min-width: 60%;
    padding: 6rem;
  `}
`;

const BlockCardGreyMediaContainer = Styled.div`
  position: relative;
  height: 22rem;
  overflow: hidden;
  
  ${Media.greaterThan('700px')`
    min-width: 40%;
    min-height: 60rem;
  `}
`;

const ImageContainer = Styled(Image)`
  position: absolute !important;
  width: 100%;
  height: 100%;
`;

const ButtonContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a > * {
    font-weight: 800;
  }
`;

const TaglineExtended = Styled(Tagline)`
  color: #030303;

  font-size: 3rem;
  line-height: 3.6rem;

  ${Media.greaterThan('1170px')`
    font-size: 4.3rem;
    line-height: 4.8rem;
  `}

`;

const HeadlineExtended = Styled(Headline)`
  margin: 5rem 0;
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 500;

  ${Media.greaterThan('medium')`
    margin: 10rem 0 6rem 0;
  `}

  ${Media.greaterThan('1200px')`
    margin: 14rem 0 6rem 0;
  `}

  ${Media.greaterThan('large')`
    font-size: 5.2rem;
    line-height: 5rem;
  `}
`;

const ContentExtended = Styled(Content)`
  text-align: left;
  margin-top: 9rem;
`;

const UIDesignPage: React.FC = ({
  data,
}) => (
  <Layout>
    <Meta
      title="UI Design Tool | UI Design Made Easy | Uizard"
      description="Design user interfaces for apps and websites in no time at all with Uizard’s AI design features and drag-and-drop editor. Sign up today!"
      url="/ui-design/"
      faqschema=""
    />

    <HeroBigTextBannerWithVideo
      tagline="UI Design Tool"
      headline="UI design, powered by AI"
      description="App and web UI design made easy with the power of AI. Go from ideation to interactive prototype in no time at all."
      imageA={data.protoCoverBig.childImageSharp}
      imageB={data.protoCoverSmall.childImageSharp}
      imageAlt="a user designing a screen in uizard for a mobile app"
      link={`https://${constants.APP_DOMAIN}/auth/sign-up`}
      cta="Sign up for free"
      tagAsTitle
      videoName="uizard-prototype-cover"
    />

    <HomeAutodesignerLeft
      headline="Create UI designs from text prompts"
      description="Generate UI designs using a few simple words with Uizard Autodesigner. Enter your design idea, select a theme, and Autodesigner will generate a multi-screen, editable mockup and a matter of seconds."
      imageA={data.autodesignerImageA.childImageSharp}
      imageB={data.autodesignerImageB.childImageSharp}
      imageAlt="turn text prompts into UI designs with AI"
      videoName="uizard-autodesigner-light"
      link="/autodesigner/"
    />

    <HomeAutodesigner
      headline="Turn screenshots into editable UI designs"
      description="Transform app and web screenshots into editable designs with Uizard's Screenshot Scanner. Iterating on established designs has never been easier."
      imageA={data.hificonversionImageA.childImageSharp}
      imageB={data.hificonversionImageB.childImageSharp}
      imageAlt="convert screen shot into editable UI mockup"
      videoName="uizard-scan-screenshot"
      link="/screenshot/"
      videoH={460}
    />

    <SectionWithLeftImage
      headline="Collaborative UI design"
      description="Work alongside your team in real time with Uizard's drag-and-drop editor to transform your ideas into design reality."
      description2="Share your project externally or open conversations for feedback and iteration, collaboration has never been easier."
      imageA={data.collaborativeUiDesignBig.childImageSharp}
      imageB={data.collaborativeUiDesignSmall.childImageSharp}
      imageAlt="Overview of Uizard's collaborative features"
      marginBottom="6rem"
    />

    <HomeFeatured />

    <BlockCardGreyContainer>
      <Content>
        <BlockCardGreyWrapper>
          <BlockCardGreyContent>
            <TaglineExtended as="h1">UI design made easy</TaglineExtended>
            <Description>
              Ready to kickstart your next UI design project? With Uizard's drag-and-drop editor,
              bringing your vision to life has never been easier.
            </Description>
            <Description>
              Generate a design with AI, use one of Uizard's premade UI design templates, or start with a blank canvas!
            </Description>
            <ButtonContainer>
              <a href="https://app.uizard.io/auth/sign-up">
                <Button>
                  Sign up for free
                </Button>
              </a>
            </ButtonContainer>
          </BlockCardGreyContent>
          <BlockCardGreyMediaContainer>
            <MediaQuery lessThan="medium">
              <ImageContainer
                fluid={'fluid' in data.mockupsDesignTemplatesSmall.childImageSharp ? data.mockupsDesignTemplatesSmall.childImageSharp.fluid : ''}
                alt="Image showcasing a few screens available as Uizard templates"
              />
            </MediaQuery>
            <MediaQuery greaterThan="medium">
              <ImageContainer
                fluid={'fluid' in data.mockupsDesignTemplatesBig.childImageSharp ? data.mockupsDesignTemplatesBig.childImageSharp.fluid : ''}
                alt="Image showcasing a few screens available as Uizard templates"
              />
            </MediaQuery>
          </BlockCardGreyMediaContainer>
        </BlockCardGreyWrapper>
      </Content>
    </BlockCardGreyContainer>

    <Content>
      <HeadlineExtended as="h2">
        Learn about UI design
      </HeadlineExtended>
    </Content>

    <SectionWithLeftImage
      headline="UI design explained"
      description="Explore the world of UI design with the Uizard blog."
      description2="Whether you're a beginner exploring the fundamentals or a seasoned designer looking for a fresh perspective, we've got you covered."
      imageA={data.whatIsUiDesignBig.childImageSharp}
      imageB={data.whatIsUiDesignSmall.childImageSharp}
      imageAlt="Overview of Uizard's collaborative features"
      link="https://uizard.io/blog/"
      reverseOrder
    />

    <BlogHighlight
      blogs={[
        {
          image: whatAreUiElements,
          link: 'https://uizard.io/blog/what-are-ui-elements-ui-design-elements-explained/?_gl=1*1iouay0*_ga*NDQ2NjM2Mjc3LjE2Njk4OTg5MDY.*_ga_FV1FRPC5G4*MTY5MjE4OTM2MC4yOC4wLjE2OTIxODkzNjAuNjAuMC4w',
          alt: 'Thumbnail for blog titled What are UI elements? User interface elements explained?',
        },
        {
          image: howToBecomeAUiDesigner,
          link: 'https://uizard.io/blog/how-to-become-a-ui-designer/?_gl=1*1alnv9d*_ga*NDQ2NjM2Mjc3LjE2Njk4OTg5MDY.*_ga_FV1FRPC5G4*MTY5MjE4OTM2MC4yOC4xLjE2OTIxOTIzMDcuNjAuMC4w',
          alt: 'Thumbnail for blog titled How to become a UI designer',
        },
        {
          image: tenUiDesignTips,
          link: 'https://uizard.io/blog/ten-ui-design-tips/?_gl=1*1tuggqp*_ga*NDQ2NjM2Mjc3LjE2Njk4OTg5MDY.*_ga_FV1FRPC5G4*MTY5MjE4OTM2MC4yOC4xLjE2OTIxOTIzMjAuNDcuMC4w',
          alt: 'Thumbnail for blog titled 10 UI design tips for your next project',
        },
      ]}
    />

    <ContentExtended>
      <FAQSection
        title="UI design FAQs"
        questions={[
          {
            question: 'What is UI design?',
            answer: 'User interface (UI) design is the discipline focused on designing the visual elements and interactive components of software. It involves creating intuitive, efficient, and aesthetically pleasing interfaces, using elements such as buttons, text elements, navigational components, and imagery. The goal is to design a product that is both user-friendly and accessible. While closely related, UI design is distinct from user experience (UX) design, which refers to the overall user experience with a product, including its functionality and accessibility.',
          },
          {
            question: 'How do I create a UI design?',
            answer: 'Creating a UI design begins with understanding the needs and expectations of users, typically through user research and persona creation. This understanding informs the design structure, deciding how users will interact with a product. Once interactions are defined, the visual design is created, taking into consideration colors, fonts, and images, whilst adhering to principles like hierarchy, balance, and contrast. Prototypes are then built to test the interface, linking different screens together to simulate user experience. User testing is crucial at this stage, providing feedback on the intuitiveness and usability of the design.',
          },
          {
            question: 'What makes a good UI design?',
            answer: 'Good UI design is intuitive, consistent, responsive, clear, visually pleasing, and accessible. It\'s easy to navigate without much guidance. With a great UI design, consistent elements promote familiarity and responsiveness and guarantees usability across various devices. Also important is clarity of design, which ensures effective communication of core messaging whilst also providing accurate feedback on user actions. Lastly, a great UI is accessible, catering to a variety of user needs, and promoting inclusiveness.',
          },
          {
            question: 'What is the best UI design tool?',
            answer: 'The "best" UI design tool often depends on the specific needs and experience level of the designer or team. However, generally speaking, drag-and-drop design tools, such as Uizard, are often favored due to their ease of use and speed. These tools allow designers to quickly assemble user interfaces without needing advanced technical skills or knowledge. They typically come with a library of pre-designed elements and components that can be arranged to create a wide variety of interface designs. This can be especially helpful for beginners, or for professionals who want to rapidly prototype different design ideas.',
          },
        ]}
      />
    </ContentExtended>

    <HomeExamples />

    <Content>
      <Shoutout
        headline="UI design. Simplified."
        description="UI design made easy with the power of AI"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default UIDesignPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    protoCoverBig: file(
      relativePath: { eq: "prototyping/Proto_Cover_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 976, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    protoCoverSmall: file(
      relativePath: { eq: "prototyping/Proto_Cover_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 488, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageA: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    autodesignerImageB: file(
      relativePath: { eq: "ai/uizard-autodesigner-light-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageA: file(
      relativePath: { eq: "ai/HifiConversion-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    hificonversionImageB: file(
      relativePath: { eq: "ai/HifiConversion-b.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    collaborativeUiDesignBig: file(
      relativePath: {
        eq: "ui-design/collaborative_ui_design_big.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1171, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    collaborativeUiDesignSmall: file(
      relativePath: {
        eq: "ui-design/collaborative_ui_design_small.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 586, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupsDesignTemplatesBig: file(
      relativePath: { eq: "mockups/Mockups_DesignTemplates2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 854, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    mockupsDesignTemplatesSmall: file(
      relativePath: { eq: "mockups/Mockups_DesignTemplates1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 427, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    whatIsUiDesignBig: file(
      relativePath: { eq: "ui-design/what_is_ui_design_big.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 730, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    whatIsUiDesignSmall: file(
      relativePath: { eq: "ui-design/what_is_ui_design_small.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 415, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
